import amplitude, { AmplitudeClient, Identify } from "amplitude-js";
import { gqlType } from "@hifieng/common";
import { getEnvVariable } from "../helpers/getEnvVariable";
import { AuthUserType, IPermissions } from "../types/AuthTypes";
import { HeartbeatStatusType } from "../types/HeartbeatTypes";
import { getAnalyticsPermissions } from "../helpers/permissions";
type AnalyticsUserType = AuthUserType & {
  permissions?: IPermissions;
};

class Analytics {
  public user: AnalyticsUserType | null;

  private _amplitudeClient: AmplitudeClient;
  private _identify: Identify;

  constructor() {
    this.user = null;

    this._amplitudeClient = amplitude.getInstance();
    this._identify = new amplitude.Identify();
    this._amplitudeClient.init(getEnvVariable("REACT_APP_AMPLITUDE_API_KEY"));
  }

  public setUserContext(analyticsUser?: AnalyticsUserType) {
    if (analyticsUser && analyticsUser.email) {
      this.user = analyticsUser || null;
      this._amplitudeClient.setUserId(analyticsUser.email);
      this._identify.set("user_id", analyticsUser.email);
      this._identify.set(
        "permissions",
        getAnalyticsPermissions(analyticsUser.permissions)
      );

      Object.entries(analyticsUser).forEach(([key, value]) => {
        if (typeof value === "boolean") {
          this._identify.set(key, value.toString());
        } else {
          if (
            typeof value === "string" ||
            Array.isArray(value) ||
            typeof value === "object"
          ) {
            this._identify.set(key, value);
          }
        }
      });

      amplitude.identify(this._identify);
    } else {
      this._amplitudeClient.setUserId(null);
    }
  }

  public heartbeatChange(
    fromStatus: HeartbeatStatusType,
    toStatus: HeartbeatStatusType
  ) {
    const message = `Heartbeat Status Change`;
    const changeInfo = { fromStatus, toStatus };
    this._captureEvent(message, changeInfo);
  }

  public pageView(
    pathName: string,
    data?: { page?: string; eventId?: string }
  ) {
    const message = `Page Viewed`;
    const pageInfo = { ...data, pathName };
    this._captureEvent(message, pageInfo);
  }

  public auditLog(auditLogInfo: { isSuccess: boolean; referenceId?: string }) {
    const message = `Audit Log Submitted`;

    this._captureEvent(message, auditLogInfo);
  }

  public appError(errorMessage: string) {
    const message = `App Error Received`;
    const errorInfo = { errorMessage };
    this._captureEvent(message, errorInfo);
  }

  public newEventNotification() {
    const message = `Event Notification Received`;
    this._captureEvent(message);
  }

  public eventShareDialogueOpen(eventId: string) {
    const message = `Event Share Dialogue Opened`;
    this._captureEvent(message, { eventId });
  }

  public eventUrlCopy(eventId: string) {
    const message = `Event Screenshot URL Copied`;
    this._captureEvent(message, { eventId });
  }

  public eventScreenshotDownload(eventId: string) {
    const message = `Event Screenshot Downloaded`;
    this._captureEvent(message, { eventId });
  }

  public eventAudioSignaturePlayed(analyticsData: {
    eventId: string;
    signatureId: string;
  }) {
    const message = `Event Audio Signature Played`;
    this._captureEvent(message, analyticsData);
  }

  public eventFileSignatureDownloaded(analyticsData: {
    eventId: string;
    signatureId: string;
  }) {
    const message = `Event File Signature Downloaded`;
    this._captureEvent(message, analyticsData);
  }

  public eventArchived(event: gqlType.EventNotification) {
    const message = `Event Archived`;
    this._captureEvent(message, event);
  }

  public eventUnarchived(event: gqlType.EventNotification) {
    const message = `Event Unarchived`;
    this._captureEvent(message, event);
  }

  public historyAddFilter(filter: {
    type: string;
    value: string | Array<string> | { startDate: string; endDate: string };
  }) {
    const message = `History Filter Added`;
    this._captureEvent(message, filter);
  }

  public historyMobileFiltersOpened() {
    const message = `History Mobile Filters Opened`;
    this._captureEvent(message);
  }

  public mapViewReset() {
    const message = "Map View Reset";
    this._captureEvent(message);
  }

  public mapAutoSearchToggled(status: "on" | "off") {
    const message = "Map Auto Search Toggled";
    this._captureEvent(message, { status });
  }

  public reportDownloaded(reportTitle: string, contentType: string) {
    const message = "Report Downloaded";
    this._captureEvent(message, { reportTitle, contentType });
  }

  public reportDeleted(reportTitle: string, contentType: string) {
    const message = "Report Deleted";
    this._captureEvent(message, { reportTitle, contentType });
  }

  public userChangeOrg(orgName: string) {
    const message = `Active Organization Changed`;
    this._captureEvent(message, { activeOrganization: orgName });
  }

  public eventDeleted(event: gqlType.EventNotification) {
    const message = `Event Deleted`;
    this._captureEvent(message, event);
  }

  private _captureEvent(message: string, data?: any) {
    this._amplitudeClient.logEvent(message, data);
  }
}

const analytics = new Analytics();

export { analytics };
