"use strict";

function __export(m) {
  for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
__export(require("./parseToken"));
__export(require("./getEnv"));
__export(require("./tuple"));
var uuid_1 = require("uuid");
exports.generateUuid = uuid_1.v4;