import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@hifieng/common";

import { ApolloError } from "apollo-boost";
import { logErrorToLogdna } from "../../../helpers/logdna";
import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import {
  trainRunFragment,
  trainsQueryComposer,
  COMPLETED_TRAINS_SUBSCRIPTION
} from "../../../helpers/trainQueryComposer";

export const fragment = gql`
  fragment TrainsFragment on PaginatedTrainsAndTotal {
    trains {
      ...TrainRunFragment
      startLocation {
        latitude
        longitude
      }
      endLocation {
        latitude
        longitude
      }
      startPost
      endPost
      numberOfUpdates
      numberOfCriticalEvents
      auditLogs {
        id
        comment {
          message
        }
      }
    }
    resultCount
  }
  ${trainRunFragment}
`;

const COMPLETED_TRAINS_QUERY = trainsQueryComposer(fragment);

export const useCompletedTrains = (filters: gqlType.TrainsFiltersInput) => {
  const { activeOrg } = useOrganizationContext();
  const [trainRunsState, setTrainRuns] = useState();

  const { subscribeToMore, loading, data } = useQuery(COMPLETED_TRAINS_QUERY, {
    variables: {
      organizationId: activeOrg ? activeOrg.id : undefined,
      skip: !activeOrg,
      filters: {
        ...filters,
        completed: true
      }
    },
    fetchPolicy: "cache-and-network",
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        "GETTING_completed_TRAINS",
        "Apollo error while getting completed trains.",
        err
      );
      throw err;
    }
  });

  useEffect(() => {
    if (!activeOrg) {
      return;
    }
    subscribeToMore({
      document: COMPLETED_TRAINS_SUBSCRIPTION,
      variables: {
        organizationId: activeOrg.id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        } else {
          const subscribed = subscriptionData.data.trackTrain;
          const prevIds = prev.trains.trains.map(
            (train: gqlType.TrainRun) => train.id
          );
          if (subscribed.completed && !prevIds.includes(subscribed.id)) {
            const prevTotal: number = prev.trains.resultCount;
            return Object.assign({}, prev, {
              trains: {
                ...prev.trains,
                trains: [subscribed, ...prev.trains.trains].sort(
                  (a, b) => b.endTime - a.endTime
                ),
                resultCount: prevTotal + 1
              }
            });
          }
          return prev;
        }
      }
    });
    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  useEffect(() => {
    if (data && data.trains) setTrainRuns(data.trains);
  }, [data]);
  const trainRuns = trainRunsState as any;
  return {
    loading,
    trainRuns,
    total: data ? data.trains.resultCount : 0
  };
};
