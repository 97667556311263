"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AssetType;
(function (AssetType) {
  AssetType["Valve"] = "valve";
  AssetType["Pump"] = "pump";
  AssetType["Loop"] = "loop";
  AssetType["Door"] = "door";
  AssetType["Anomaly"] = "anomaly";
  AssetType["Coupler"] = "coupler";
  AssetType["Gap"] = "gap";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
var Measures;
(function (Measures) {
  Measures["Rms"] = "RMS";
  Measures["StrainTemp"] = "STRAIN_TEMP";
  Measures["Bprms"] = "BPRMS";
})(Measures = exports.Measures || (exports.Measures = {}));
var PigStatus;
(function (PigStatus) {
  PigStatus["Moving"] = "MOVING";
  PigStatus["NotDetected"] = "NOT_DETECTED";
  PigStatus["Stopped"] = "STOPPED";
  PigStatus["Completed"] = "COMPLETED";
  PigStatus["ApproachingPoi"] = "APPROACHING_POI";
  PigStatus["Detected"] = "DETECTED";
})(PigStatus = exports.PigStatus || (exports.PigStatus = {}));
var PigType;
(function (PigType) {
  PigType["Utility"] = "UTILITY";
  PigType["Inspection"] = "INSPECTION";
  PigType["Other"] = "OTHER";
})(PigType = exports.PigType || (exports.PigType = {}));
var PointOfInterestType;
(function (PointOfInterestType) {
  PointOfInterestType["Lake"] = "lake";
})(PointOfInterestType = exports.PointOfInterestType || (exports.PointOfInterestType = {}));
var SortByTypes;
(function (SortByTypes) {
  SortByTypes["TimeAsc"] = "TIME_ASC";
  SortByTypes["TimeDesc"] = "TIME_DESC";
})(SortByTypes = exports.SortByTypes || (exports.SortByTypes = {}));
var TimeUnits;
(function (TimeUnits) {
  TimeUnits["Seconds"] = "SECONDS";
  TimeUnits["Minutes"] = "MINUTES";
  TimeUnits["Hours"] = "HOURS";
  TimeUnits["Days"] = "DAYS";
})(TimeUnits = exports.TimeUnits || (exports.TimeUnits = {}));
var TrainStatus;
(function (TrainStatus) {
  TrainStatus["Moving"] = "MOVING";
  TrainStatus["NotDetected"] = "NOT_DETECTED";
  TrainStatus["Stopped"] = "STOPPED";
  TrainStatus["Completed"] = "COMPLETED";
  TrainStatus["ApproachingStation"] = "APPROACHING_STATION";
  TrainStatus["ApproachingPoi"] = "APPROACHING_POI";
  TrainStatus["Detected"] = "DETECTED";
})(TrainStatus = exports.TrainStatus || (exports.TrainStatus = {}));
var TrainType;
(function (TrainType) {
  TrainType["Utility"] = "UTILITY";
  TrainType["Inspection"] = "INSPECTION";
  TrainType["Other"] = "OTHER";
})(TrainType = exports.TrainType || (exports.TrainType = {}));
var UpdatesType;
(function (UpdatesType) {
  UpdatesType["Critical"] = "CRITICAL";
  UpdatesType["NonCritical"] = "NON_CRITICAL";
})(UpdatesType = exports.UpdatesType || (exports.UpdatesType = {}));