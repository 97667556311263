import React, { useContext } from "react";

type PropsType = {
  children: React.ReactNode;
};

interface IContextType {
  openModal?: string;
  setOpenModal: (arg0: string) => void;
}

const INITIAL_CONTEXT: IContextType = {
  openModal: "",
  setOpenModal: () => {
    throw new Error("Modal context has not yet been initialized.");
  }
};

export const ModalContext = React.createContext(INITIAL_CONTEXT);
export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = (props: PropsType) => {
  const [openModal, setOpenModal] = React.useState<string | undefined>(undefined);

  return (
    <ModalContext.Provider value={{ openModal, setOpenModal }}>
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
