import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Gap = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 13 9">
      <g
        fill="none"
        stroke="white"
        strokeWidth=".63158"
        fillRule="evenodd"
        transform="translate(.5 0.6)"
      >
        <path d="M11 4.45885L.78314 4.45885" />
      </g>
    </svg>
  );
};

export default Gap;
