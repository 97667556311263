/* eslint-disable no-undef*/
import React, { RefObject } from "react";
import { forceSimulation, forceCollide, forceX, forceY } from "d3-force";
import { select } from "d3-selection";
import ReactDOMServer from "react-dom/server";
import cx from "classnames";

import { Text } from "../../Type";
import { IconByType } from "../../Icons";
import { color } from "../../../styles/colors";
import styles from "./index.module.scss";

import {
  SchematicMapClusterPointType,
  EnrichedClusterPoint
} from "./clusterPoints";

type PropsType = {
  isOpen: boolean;
  onToggle: (clusterId: string) => void;
  point: SchematicMapClusterPointType;
  yPosition: number;
  xPosition: number;
  xAxisWidth: number;
};

type PointNodeType = {
  index: number;
  x: number;
  y: number;
  vy?: number;
  point: EnrichedClusterPoint;
};

const d3 = {
  forceSimulation,
  forceCollide,
  forceY,
  forceX,
  select
};

const ICON_SIZE = 12;
const ICON_CIRCLE_RADIUS = 12;
const CLUSTER_POINT_WIDTH = 28;
const CLUSTER_POINT_PADDING = 10;
const CLUSTER_POINT_OPEN_Y_CHANGE = -45;
const TYPE_TEXT_OFFSET = 40;
const POST_TEXT_OFFSET = 25;
const HALF = 0.5;
const DOUBLE = 2;
const POST_LINE_Y_VALUE = 82;

class Cluster extends React.Component<PropsType> {
  clusterRef: RefObject<SVGGElement> = React.createRef();
  clusterPoints!: Array<EnrichedClusterPoint>;
  pointNodes!: Array<PointNodeType>;
  iconNode!: d3.Selection<SVGSVGElement, PointNodeType, d3.BaseType, unknown>;
  circleNode!: d3.Selection<
    SVGCircleElement,
    PointNodeType,
    d3.BaseType,
    unknown
  >;
  typeNode!: d3.Selection<SVGTextElement, PointNodeType, d3.BaseType, unknown>;
  postNode!: d3.Selection<SVGTextElement, PointNodeType, d3.BaseType, unknown>;
  simulation!: d3.Simulation<PointNodeType, undefined>;

  constructor(props: PropsType) {
    super(props);

    this.initSimulation();
  }

  componentDidMount() {
    this.initSimulation();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(lastProps: PropsType) {
    if (lastProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.openCluster();
      } else {
        this.closeCluster();
      }
    }
    return null;
  }

  initSimulation = () => {
    this.clusterPoints = this.props.point.clusterPoints || [];
    this.pointNodes = this.clusterPoints.map((point, idx) => {
      return {
        index: idx,
        x: this.props.xPosition,
        y: this.props.yPosition,
        point
      };
    });

    this.typeNode = d3
      .select(this.clusterRef.current)
      .selectAll("g")
      .data(this.pointNodes)
      .enter()
      .append("text")
      .text(d => d.point.type)
      .attr("text-anchor", "middle")
      .attr("class", styles.TextNode);

    this.postNode = d3
      .select(this.clusterRef.current)
      .selectAll("g")
      .data(this.pointNodes)
      .enter()
      .append("text")
      .text(d => d.point.post)
      .attr("text-anchor", "middle");

    this.circleNode = d3
      .select(this.clusterRef.current)
      .selectAll("g")
      .data(this.pointNodes)
      .enter()
      .append("circle")
      .attr("r", ICON_CIRCLE_RADIUS)
      .attr("class", styles.IconCircle);

    this.iconNode = d3
      .select(this.clusterRef.current)
      .selectAll("g")
      .data(this.pointNodes)
      .enter()
      .append("svg")
      .attr("height", "28")
      .attr("width", "28")
      .attr("viewBox", "0 0 28 28")
      .html(d => {
        return ReactDOMServer.renderToStaticMarkup(
          <IconByType type={d.point.type} size={ICON_SIZE} />
        );
      });

    this.simulation = d3.forceSimulation(this.pointNodes).on("tick", () => {
      this.typeNode
        .attr("x", (d: { x: number }) => d.x)
        .attr("y", (d: { y: number }) => d.y - TYPE_TEXT_OFFSET);

      this.postNode
        .attr("x", (d: { x: number }) => d.x)
        .attr("y", (d: { y: number }) => d.y - POST_TEXT_OFFSET);

      this.circleNode
        .attr("cx", (d: { x: number }) => d.x)
        .attr("cy", (d: { y: number; vy: number }) => {
          // Snap icons to post line
          if (d.y < POST_LINE_Y_VALUE) {
            d.vy = 0;
            return POST_LINE_Y_VALUE;
          } else {
            return d.y;
          }
        });

      this.iconNode
        .attr("x", (d: { x: number }) => d.x - ICON_SIZE * HALF) // Adjust for icon size
        .attr("y", (d: { y: number }) => d.y - ICON_SIZE * HALF); // Adjust for icon size
    });

    if (this.props.isOpen) {
      this.openCluster();
    } else {
      this.closeCluster();
    }
  };

  onResize = () => {
    if (this.props.isOpen) {
      this.props.onToggle(this.props.point.id);
    }

    // Remove all the old nodes before re-initializing the simulation.
    // This prevents multiple and/or mispositioned elements.
    const clusterElement = d3.select(this.clusterRef.current);
    clusterElement.selectAll("svg").remove();
    clusterElement.selectAll("circle").remove();
    clusterElement.selectAll("text").remove();

    this.initSimulation();
  };

  /*
   * Calculates the required cluster group shift along the x axis
   * where the cluster group overlaps the axis edge.
   */
  calculateXShift() {
    let xShift = 0;
    const expandedClusterWidth =
      this.clusterPoints.length * (CLUSTER_POINT_WIDTH + CLUSTER_POINT_PADDING);

    // Handle cluster group overlapping left axis edge
    if (this.props.xPosition - HALF * expandedClusterWidth < 0) {
      xShift = -(
        this.props.xPosition -
        HALF * (expandedClusterWidth - DOUBLE * CLUSTER_POINT_PADDING)
      );
    }

    // Handle cluster group overlapping right axis edge
    if (
      this.props.xPosition +
        HALF * (expandedClusterWidth - DOUBLE * CLUSTER_POINT_PADDING) >
      this.props.xAxisWidth
    ) {
      xShift = -(
        this.props.xPosition +
        HALF * expandedClusterWidth -
        this.props.xAxisWidth
      );
    }

    return xShift;
  }

  openCluster = () => {
    const xShift = this.calculateXShift();

    this.typeNode.attr("class", styles.TextNode);
    this.postNode.attr("class", styles.TextNode);

    this.simulation
      .force(
        "charge",
        d3
          .forceCollide()
          .radius((CLUSTER_POINT_WIDTH + CLUSTER_POINT_PADDING) * HALF)
      )
      .force(
        "forceY",
        d3
          .forceY()
          .strength(0.9) //eslint-disable-line no-magic-numbers
          .y(this.props.yPosition + CLUSTER_POINT_OPEN_Y_CHANGE)
      )
      .force(
        "forceX",
        d3
          .forceX()
          .strength(0.1) //eslint-disable-line no-magic-numbers
          .x(d => {
            return (d as PointNodeType).point.xPosition + xShift; //eslint-disable-line @typescript-eslint/restrict-plus-operands
          })
      )
      .alpha(0.6) //eslint-disable-line no-magic-numbers
      .restart();
  };

  closeCluster = () => {
    this.typeNode.attr("class", `${styles.TextNode} ${styles.FadeOut}`);
    this.postNode.attr("class", `${styles.TextNode} ${styles.FadeOut}`);

    this.simulation
      .force("charge", null)
      .force(
        "forceY",
        d3
          .forceY()
          .strength(0.5) //eslint-disable-line no-magic-numbers
          .y(this.props.yPosition)
      )
      .force(
        "forceX",
        d3
          .forceX()
          .strength(0.75) //eslint-disable-line no-magic-numbers
          .x(this.props.xPosition)
      )
      .alpha(0.75) //eslint-disable-line no-magic-numbers
      .restart();
  };

  toggleCluster = () => {
    this.props.onToggle(this.props.point.id);
  };

  render() {
    const { point, xPosition, yPosition, isOpen } = this.props;

    // There are lots of layout numbers in here which can't be done in the styles
    /* eslint-disable no-magic-numbers */
    return (
      <g key={point.id}>
        <g ref={this.clusterRef}></g>

        <g
          className={styles.IconButton}
          onClick={this.toggleCluster}
          onKeyDown={(e: React.KeyboardEvent<SVGCircleElement>) => {
            if (e.which === 13) this.toggleCluster();
          }}
          tabIndex={0}
        >
          {/* Focus State Outline */}
          <circle
            stroke={"currentColor"}
            strokeWidth={1}
            fill="none"
            cy={yPosition}
            cx={xPosition}
            r={isOpen ? 24 : 20}
          />
          {/* Outer Ring */}
          <circle
            stroke={isOpen ? color.gray : color.mineShaft}
            strokeWidth={3}
            fill="none"
            cy={yPosition}
            cx={xPosition}
            r={isOpen ? 20 : 17}
          />
          {/* Inner Ring */}
          <circle
            stroke={color.darkMineShaft}
            strokeWidth={3}
            fill={color.codGray}
            cy={yPosition}
            cx={xPosition}
            r={isOpen ? 17 : 14}
          />
          <Text
            component="text"
            size="small"
            className={cx(styles.IconCount, {
              [styles.IconCountOpen]: isOpen
            })}
            textAnchor="middle"
            y={yPosition + 4}
            x={xPosition}
          >
            {this.clusterPoints.length}
          </Text>
        </g>
      </g>
    );
    /* eslint-enable */
  }
}

export default Cluster;
