import React, { useState } from "react";
import { gqlType } from "@hifieng/common";
import { Text } from "../../Type";
import styles from "./index.module.scss";
import cx from "classnames";
import PipelineIcon from "../../Icons/Pipeline";

type PropsType = {
  pipelines: Array<gqlType.Pipeline>;
  resetPan: (pipelines: Array<gqlType.Pipeline>, resetFlag: boolean) => void;
  initialFocusedPipeline?: string;
  mapResetFlag: boolean;
};

const PipelineLegend = ({
  pipelines,
  resetPan,
  initialFocusedPipeline,
  mapResetFlag
}: PropsType) => {
  // Filters out pipelines with with config flag interfaceOptions.hideFromMapLegend set to True
  const filteredPipelines = pipelines.filter(
    pipeline => !pipeline.interfaceOptions.hideFromMapLegend
  );

  const [focusedPipeline, setFocusedPipeline] = useState(
    initialFocusedPipeline
  );

  const focusPipeline = (pipeline: gqlType.Pipeline) => {
    // Zooms to a pipeline on the map, and sets it as the focused pipeline for legend highlighting.
    setFocusedPipeline(pipeline.id);
    resetPan([pipeline], true);
  };
  mapResetFlag;
  return (
    <div className={styles.PipelineLegend}>
      {filteredPipelines.map((pipeline, index) => (
        <div className={styles.PipelineLegendRow} key={index}>
          <button
            type="button"
            onClick={() => focusPipeline(pipeline)}
            className={cx(styles.Pipeline, {
              [styles.PipelineFocused]:
                focusedPipeline === pipeline.id && mapResetFlag
            })}
          >
            <PipelineIcon
              className={styles.PipelineColor}
              color={pipeline.color}
            />
            <Text size="small" className={styles.PipelineName}>
              {pipeline.name}
            </Text>
          </button>
        </div>
      ))}
    </div>
  );
};

export default PipelineLegend;
