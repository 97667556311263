"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEnv = function (name, allowEmpty) {
  if (!allowEmpty && process.env[name] === undefined) {
    throw new Error("Attempted to use undefined environment variable ".concat(name));
  }
  return process.env[name] || "";
};