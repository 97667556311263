import React from "react";

import {
  Acoustic,
  Construction,
  Intrusion,
  Leak,
  Exclamation,
  Loop,
  Flow,
  Frequency,
  Pig,
  Power,
  Pump,
  Strain,
  Seismic,
  Temperature,
  Test,
  Train,
  Door,
  Valve,
  Gap
} from "../";

type iconTypes =
  | "acoustic"
  | "construction"
  | "intrusion"
  | "leak"
  | "flow"
  | "loop"
  | "frequency"
  | "pig"
  | "power"
  | "pump"
  | "seismic"
  | "strain"
  | "temperature"
  | "test"
  | "train"
  | "door"
  | "valve"
  | "gap";

type PropsType = {
  type: string;
  size?: number;
};

// iconIconMap is intentionally defined inside of this component. It would appear that it could be
// pulled out, but due to the how renderToStaticMarkup works in getEncodedIconByType, this needs to
// be pulled in.
const IconByType = ({ type, size }: PropsType) => {
  const iconMap = {
    acoustic: Acoustic,
    construction: Construction,
    intrusion: Intrusion,
    leak: Leak,
    loop: Loop,
    flow: Flow,
    frequency: Frequency,
    pig: Pig,
    power: Power,
    pump: Pump,
    seismic: Seismic,
    strain: Strain,
    temperature: Temperature,
    test: Test,
    train: Train,
    door: Door,
    valve: Valve,
    anomaly: Door,
    coupler: Loop,
    gap: Gap
  };

  if (!iconMap.hasOwnProperty(type)) return <Exclamation size={size} />;
  const Icon = iconMap[type as iconTypes];
  return <Icon size={size} />;
};

export default IconByType;
