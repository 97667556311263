/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useContext, useCallback } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";

import { parseTokenPermissions } from "../helpers/parseTokenPermissions";
import {
  IAuthContext,
  DecodedTokenType,
  IPermissions
} from "../types/AuthTypes";
import { logErrorToLogdna } from "../helpers/logdna";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
/* eslint-disable no-undef*/
type PropsType = {
  children: React.ReactNode;
  onRedirectCallback?: ((p: any) => void) | undefined;
} & Auth0ClientOptions;

const initialAuthState: IAuthContext = {
  isAuthenticated: false,
  loading: true,
  permissions: { user: {}, org: {} },
  getSessionToken: () => {
    throw new Error("Auth context has not yet been initialized.");
  },
  loginWithRedirect: () => {
    throw new Error("Auth context has not yet been initialized.");
  },
  logout: () => {
    throw new Error("Auth context has not yet been initialized.");
  }
};

const ERROR_CODES = {
  tokenFetchFailed: "Could not get an authentication token"
};

// https://auth0.com/docs/quickstart/spa/react#install-the-auth0-react-wrapper
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext(initialAuthState);
export const useAuth = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: PropsType) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [auth0Client, setAuth0] = useState({});
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<IPermissions>(
    initialAuthState.permissions
  );

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (e) {
          console.error(e);
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, []); //  eslint-disable-line react-hooks/exhaustive-deps

  const getSessionToken = useCallback(async () => {
    if (!auth0Client) {
      return Promise.reject(new Error("Auth0 client not initialized"));
    }
    const auth0ClientInstance = auth0Client as Auth0Client;
    try {
      const token = await auth0ClientInstance.getTokenSilently();
      const decodedToken: DecodedTokenType = jwtDecode(token);

      setPermissions(parseTokenPermissions(decodedToken));
      return { token, decodedToken };
    } catch (err) {
      const errAny = err as any;
      const errorType = err && errAny.error ? errAny.error : undefined;
      const originalMessage =
        err && errAny.error_description
          ? errAny.error_description
          : "Failed to fetch valid user token";
      // @ts-ignore
      const message = `${originalMessage}; User: ${user.email}`;
      console.error(message);
      logErrorToLogdna(ERROR_CODES.tokenFetchFailed, message, err);

      // We should logout on any of these errors:
      // https://auth0.com/docs/authorization/configure-silent-authentication#error-responses
      if (
        errorType === "login_required" ||
        errorType === "consent_required" ||
        errorType === "interaction_required"
      ) {
        auth0ClientInstance.logout();
      }
      return Promise.reject(err);
    }
  }, [auth0Client, user]);

  // Sets permissions when isAuthenticated state changes
  useEffect(() => {
    if (isAuthenticated) {
      getSessionToken();
    }
  }, [isAuthenticated]); //  eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        permissions,
        loading,
        getSessionToken,
        loginWithRedirect: () => (auth0Client as Auth0Client).loginWithRedirect(),
        logout: () => (auth0Client as Auth0Client).logout()
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
