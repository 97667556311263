/* eslint-disable no-undef*/
import React, { RefObject } from "react";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleLinear, scaleBand } from "d3-scale";
import { select } from "d3-selection";
import { line } from "d3-shape";
import cx from "classnames";

import { Text } from "../Type";
import getPriorityLevel from "../../helpers/getPriorityLevel";
import Cluster from "./Cluster";
import IconByType from "../Icons/IconByType";
import { clusterPoints } from "./Cluster/clusterPoints";
import styles from "./index.module.scss";
import { kpToMp } from "../AnalysisCharts/BaseChart";

const d3 = { line, scaleLinear, scaleBand, axisBottom, axisLeft, select };

type PropsType = {
  featuredEvent: SchematicMapDataPointType;
  events: Array<SchematicMapDataPointType>;
  assets: Array<SchematicMapDataPointType>;
  pipelineDomain: Array<number>;
  iconLabel?: string;
  imperialFlag?: boolean;
  teckFlag?: boolean;
};

type StateType = {
  width: number;
  xAxisWidth: number;
  focusEventId: string;
  focusAssetId: string;
  activeClusterId: string | undefined;
  displayFeaturedEvent: SchematicMapDataPointType;
  displayAssets: Array<SchematicMapDataPointType>;
  displayEvents: Array<SchematicMapDataPointType>;
};

export type SchematicMapCategoryType =
  | "event"
  | "KM post"
  | "Mile post"
  | "Idler #"
  | "Idler # a"
  | "asset"
  | "pig"
  | "train";

export type SchematicMapDataPointType = {
  id: string;
  category: SchematicMapCategoryType;
  isFeatured?: boolean;
  type: string;
  post: number;
};

const HEIGHT = 170;
const MARGIN = {
  right: 0,
  left: 70
};
const MIN_TICK_WIDTH = 50;
const ICON_SIZE = 12;
const PIXELS_BETWEEN_EVENTS_AND_POSTS_LINES = 33;
const Y_AXIS_TICK_PADDING = 20;
const HALF_FACTOR = 2;

const sortFocusEventFirst = (focusEventId: string) => (
  a: SchematicMapDataPointType
) => {
  if (a.id === focusEventId) {
    return 1;
  } else {
    return -1;
  }
};

class SchematicMap extends React.Component<PropsType, StateType> {
  schematicMapRef: RefObject<SVGSVGElement> = React.createRef();
  yAxisRef: RefObject<SVGSVGElement> = React.createRef();
  eventsLineRef: RefObject<SVGSVGElement> = React.createRef();
  postsLineRef: RefObject<SVGSVGElement> = React.createRef();
  postsLineRefTop: RefObject<SVGSVGElement> = React.createRef();
  postsLineRefBot: RefObject<SVGSVGElement> = React.createRef();
  assetsLineRef: RefObject<SVGSVGElement> = React.createRef();

  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleBand<string>;
  yAxis: d3.Axis<string>;
  eventsLine: d3.Axis<number | { valueOf(): number }>;
  postsLine: d3.Axis<number | { valueOf(): number }>;
  postsLineTop: d3.Axis<number | { valueOf(): number }>;
  postsLineBot: d3.Axis<number | { valueOf(): number }>;
  assetsLine: d3.Axis<number | { valueOf(): number }>;

  constructor(props: PropsType) {
    super(props);

    this.state = {
      width: 0,
      xAxisWidth: 0,
      focusEventId: this.props.featuredEvent.id || "",
      focusAssetId: "",
      activeClusterId: undefined,
      displayFeaturedEvent: Object.assign(this.props.featuredEvent, {
        post: this.props.imperialFlag
          ? kpToMp(this.props.featuredEvent.post)
          : this.props.featuredEvent.post
      }),
      displayAssets: this.props.imperialFlag
        ? this.props.assets.map(function(asset) {
            asset.post = kpToMp(asset.post);
            return asset;
          })
        : this.props.assets,
      displayEvents: this.props.imperialFlag
        ? this.props.events.map(function(event) {
            event.post = kpToMp(event.post);
            return event;
          })
        : this.props.assets
    };

    const postName = this.props.teckFlag
      ? "Idler #"
      : this.props.imperialFlag
      ? "Mile post"
      : "KM post";
    const yDomain: Array<SchematicMapCategoryType> = [
      "event",
      postName,
      "asset"
    ];
    // If it's a pig, name it as such
    if (props.featuredEvent.category === "pig") {
      yDomain[0] = "pig";
    }

    this.xScale = d3.scaleLinear().domain(
      this.props.imperialFlag
        ? this.props.pipelineDomain.map(function(element) {
            return kpToMp(element);
          })
        : this.props.pipelineDomain
    );
    this.yScale = d3
      .scaleBand()
      .rangeRound([0, HEIGHT])
      .padding(1)
      .domain(yDomain);

    this.yAxis = d3.axisLeft(this.yScale).tickPadding(Y_AXIS_TICK_PADDING);
    this.eventsLine = d3.axisBottom(this.xScale);
    this.postsLine = d3.axisBottom(this.xScale);
    this.postsLineTop = d3.axisBottom(this.xScale);
    this.postsLineBot = d3.axisBottom(this.xScale);
    this.assetsLine = d3.axisBottom(this.xScale);
  }

  updateSchemaWidth = () => {
    const newWidth =
      this.schematicMapRef && this.schematicMapRef.current
        ? this.schematicMapRef.current.getBoundingClientRect().width
        : 0;
    const newXAxisWidth = newWidth - MARGIN.right - MARGIN.left;

    // Update the x axis scale to be the container width excluding side margins
    this.xScale.rangeRound([0, newXAxisWidth]);
    /* eslint-disable no-magic-numbers */
    const idlerSlope = Math.floor((343 - 25) / this.props.pipelineDomain[1]);
    /* eslint-enable no-magic-numbers */

    // Update new maximum number of ticks
    const numTicksPost = Math.floor(newXAxisWidth / MIN_TICK_WIDTH);
    numTicksPost;

    /* eslint-disable no-magic-numbers */
    const midValues = [25, 53, 85, 118, 150, 186, 216, 247, 282, 312, 343];
    const firstIdlerValue = 25;
    /* eslint-enable no-magic-numbers */

    for (let i = 0, length = midValues.length; i < length; i++) {
      midValues[i] = (midValues[i] - firstIdlerValue) / idlerSlope;
    }
    if (this.props.teckFlag) {
      this.postsLine
        .tickValues(midValues)
        .tickFormat((d: any) => Math.round(d * idlerSlope + firstIdlerValue)); //eslint-disable-line @typescript-eslint/no-explicit-any
    } else {
      this.postsLine.ticks(numTicksPost);
    }
    /* eslint-disable no-magic-numbers */
    const topValues = [
      31,
      42,
      59,
      72,
      91,
      104,
      124,
      138,
      157,
      172,
      192,
      204,
      222,
      235,
      254,
      268,
      300,
      318,
      331
    ];
    /* eslint-enable no-magic-numbers */

    for (let i = 0, length = topValues.length; i < length; i++) {
      topValues[i] = (topValues[i] - firstIdlerValue) / idlerSlope;
    }
    this.postsLineTop
      .tickValues(topValues)
      .tickFormat((d: any) => Math.round(d * idlerSlope + firstIdlerValue)); //eslint-disable-line @typescript-eslint/no-explicit-any
    /* eslint-disable no-magic-numbers */
    const botValues = [
      36,
      47,
      66,
      79,
      98,
      111,
      131,
      144,
      164,
      179,
      198,
      210,
      241,
      261,
      275,
      294,
      306,
      324,
      337
    ];
    /* eslint-enable no-magic-numbers */

    for (let i = 0, length = botValues.length; i < length; i++) {
      botValues[i] = (botValues[i] - firstIdlerValue) / idlerSlope;
    }
    this.postsLineBot
      .tickValues(botValues)
      .tickFormat((d: any) => Math.round(d * idlerSlope + firstIdlerValue)); //eslint-disable-line @typescript-eslint/no-explicit-any
    this.setState({
      width: newWidth,
      xAxisWidth: newXAxisWidth
    });
  };

  pointHoverStart(d: SchematicMapDataPointType) {
    if (d.category === "event" || d.category === "pig") {
      this.setState({
        focusEventId: d.id
      });
    }
    if (d.category === "asset") {
      this.setState({
        focusAssetId: d.id
      });
    }
  }

  pointHoverEnd(d: SchematicMapDataPointType) {
    if (d.category === "event" || d.category === "pig") {
      this.setState({
        focusEventId: this.props.featuredEvent.id
      });
    }
  }

  onClusterToggle(clusterId: string) {
    this.setState(state => {
      const newActiveClusterId =
        state.activeClusterId === clusterId ? undefined : clusterId;

      return {
        ...state,
        activeClusterId: newActiveClusterId
      };
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSchemaWidth);
    this.updateSchemaWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSchemaWidth);
  }

  componentDidUpdate() {
    // Update width if container changed
    // Handles case where  scrollbar is added after the initial mount.
    // Addition of scrollbar does not trigger the "resize" event listener.
    const curWidth =
      this.schematicMapRef && this.schematicMapRef.current
        ? this.schematicMapRef.current.getBoundingClientRect().width
        : 0;

    if (curWidth !== this.state.width) {
      this.updateSchemaWidth();
    }

    const lineGenerator = d3.line();
    const yEventsPosition = this.yScale("event") || this.yScale("pig") || 0;
    const yAssetsPosition = this.yScale("asset") || 0;
    const eventsLineData: Array<[number, number]> = [
      [0, yEventsPosition],
      [this.state.xAxisWidth, yEventsPosition]
    ];
    const assetsLineData: Array<[number, number]> = [
      [0, yAssetsPosition],
      [this.state.xAxisWidth, yAssetsPosition]
    ];
    const eventsLinePath = lineGenerator(eventsLineData) || "";
    const assetsLinePath = lineGenerator(assetsLineData) || "";

    d3.select(this.yAxisRef.current)
      .call(this.yAxis as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll("line").remove())
      .call(g => {
        if (this.state.activeClusterId) {
          g.selectAll(".tick").attr(
            "class",
            cx("tick", styles.Fadable, styles.FadeOutXAxis)
          );
        } else {
          g.selectAll(".tick").attr("class", cx("tick", styles.Fadable));
        }
      });

    d3.select(this.eventsLineRef.current)
      .call(this.eventsLine as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      .call(g => g.select(".domain").attr("d", eventsLinePath))
      .call(g => g.selectAll(".tick").remove());

    d3.select(this.postsLineRef.current)
      .call(this.postsLine as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll("line").remove())
      .call(g =>
        g.selectAll(".tick").attr("class", `tick ${styles.LinePostsText}`)
      );

    d3.select(this.postsLineRefTop.current)
      .call(this.postsLineTop as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll("line").remove())
      .call(g =>
        g.selectAll(".tick").attr("class", `tick ${styles.LinePostsText}`)
      );

    d3.select(this.postsLineRefBot.current)
      .call(this.postsLineBot as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll("line").remove())
      .call(g =>
        g.selectAll(".tick").attr("class", `tick ${styles.LinePostsText}`)
      );

    d3.select(this.assetsLineRef.current)
      .call(this.assetsLine as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      .call(g => g.select(".domain").attr("d", assetsLinePath))
      .call(g => g.selectAll(".tick").remove());
  }

  render() {
    const { iconLabel } = this.props;
    const eventsData = [
      ...this.state.displayEvents,
      this.state.displayFeaturedEvent
    ].sort(sortFocusEventFirst(this.state.focusEventId));

    const assetsData = clusterPoints(this.state.displayAssets, {
      xScale: this.xScale,
      xDomain: this.props.pipelineDomain,
      xAxisWidth: this.state.xAxisWidth,
      category: "asset",
      iconSize: ICON_SIZE,
      teckFlag: this.props.teckFlag
    });

    return (
      <div className={styles.Container}>
        <svg className={styles.SchematicMap} ref={this.schematicMapRef}>
          {this.state.width && (
            <>
              <g>
                <g ref={this.yAxisRef} className={styles.YAxis} />
                <g
                  ref={this.eventsLineRef}
                  className={cx(
                    styles.Line,
                    styles.LineEvents,
                    styles.Fadable,
                    {
                      [styles.FadeOut]: !!this.state.activeClusterId
                    }
                  )}
                />
                <g
                  ref={this.postsLineRef}
                  className={cx(
                    styles.Line,
                    this.props.teckFlag
                      ? styles.LinePostsBlue
                      : styles.LinePosts,
                    styles.Fadable,
                    {
                      [styles.FadeOut]: !!this.state.activeClusterId
                    }
                  )}
                />
                {this.props.teckFlag ? (
                  <g
                    ref={this.postsLineRefTop}
                    className={cx(
                      styles.Line,
                      styles.LinePostsTop,
                      styles.Fadable,
                      {
                        [styles.FadeOut]: !!this.state.activeClusterId
                      }
                    )}
                  />
                ) : (
                  undefined
                )}
                <g
                  ref={this.assetsLineRef}
                  className={cx(styles.Line, styles.LineAssets)}
                />
                {this.props.teckFlag ? (
                  <g
                    ref={this.postsLineRefBot}
                    className={cx(
                      styles.Line,
                      styles.LinePostsBot,
                      styles.Fadable,
                      {
                        [styles.FadeOut]: !!this.state.activeClusterId
                      }
                    )}
                  />
                ) : (
                  undefined
                )}
              </g>
              <g
                className={cx(styles.IconGroup, styles.Fadable, {
                  [styles.FadeOut]: !!this.state.activeClusterId
                })}
              >
                {eventsData.map(d => {
                  const eventPriority = getPriorityLevel(d.type);
                  const isPig = d.category === "pig";
                  const yPosition = this.yScale(d.category) || 0;
                  const xPosition = this.xScale(d.post);

                  return (
                    <g
                      key={d.id}
                      onMouseOver={() => this.pointHoverStart(d)}
                      onMouseOut={() => this.pointHoverEnd(d)}
                      onBlur={() => this.pointHoverStart(d)}
                      onFocus={() => this.pointHoverEnd(d)}
                    >
                      {d.isFeatured && (
                        <line
                          className={styles.EventFeaturedLine}
                          y1={yPosition}
                          x1={xPosition}
                          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                          y2={yPosition + PIXELS_BETWEEN_EVENTS_AND_POSTS_LINES}
                          x2={xPosition}
                        />
                      )}
                      <circle
                        className={cx(styles.Icon, {
                          [styles.IconEventLow]: eventPriority === "low",
                          [styles.IconEventMedium]: eventPriority === "medium",
                          [styles.IconEventHigh]: eventPriority === "high",
                          [styles.IconAsset]: d.category === "asset"
                        })}
                        cy={yPosition}
                        cx={xPosition}
                        r={12}
                      />
                      <Text
                        component="text"
                        className={cx(styles.EventText, {
                          [styles.EventTextFocus]:
                            d.id === this.state.focusEventId
                        })}
                        dy={-35}
                        y={yPosition}
                        x={xPosition}
                        textAnchor="middle"
                      >
                        {iconLabel || d.type}
                      </Text>
                      <Text
                        component="text"
                        className={cx(styles.EventText, {
                          [styles.EventTextFocus]:
                            d.id === this.state.focusEventId
                        })}
                        dy={-20}
                        y={yPosition}
                        x={xPosition}
                        textAnchor="middle"
                      >
                        {this.props.teckFlag ? "" : d.post}
                      </Text>
                      <svg
                        viewBox={`0 0 ${ICON_SIZE} ${ICON_SIZE}`}
                        height={ICON_SIZE}
                        width={ICON_SIZE}
                        y={yPosition - ICON_SIZE / HALF_FACTOR}
                        x={xPosition - ICON_SIZE / HALF_FACTOR}
                      >
                        <IconByType
                          type={isPig ? "pig" : d.type}
                          size={ICON_SIZE}
                        />
                      </svg>
                    </g>
                  );
                })}
              </g>

              <g className={styles.IconGroup}>
                {assetsData.map(d => {
                  const yPosition = this.yScale(d.category) || 0;
                  const xPosition = this.xScale(d.post);

                  if (d.type === "cluster") {
                    const isOpen = this.state.activeClusterId === d.id;
                    return (
                      <Cluster
                        key={d.id}
                        isOpen={isOpen}
                        onToggle={clusterId => this.onClusterToggle(clusterId)}
                        point={d}
                        yPosition={yPosition}
                        xPosition={xPosition}
                        xAxisWidth={this.state.xAxisWidth}
                      />
                    );
                  }

                  return (
                    <g
                      key={d.id}
                      onMouseOver={() => this.pointHoverStart(d)}
                      onMouseOut={() => this.pointHoverEnd(d)}
                      onBlur={() => this.pointHoverStart(d)}
                      onFocus={() => this.pointHoverEnd(d)}
                    >
                      <circle
                        className={cx(styles.Icon, styles.IconAsset)}
                        cy={yPosition}
                        cx={xPosition}
                        r={12}
                      />
                      <Text
                        component="text"
                        className={cx(styles.AssetText, {
                          [styles.AssetTextFocus]:
                            d.id === this.state.focusAssetId
                        })}
                        dy={30}
                        y={yPosition}
                        x={xPosition}
                        textAnchor="middle"
                      >
                        {d.type}
                      </Text>
                      <Text
                        component="text"
                        className={cx(styles.AssetText, {
                          [styles.AssetTextFocus]:
                            d.id === this.state.focusAssetId
                        })}
                        dy={45}
                        y={yPosition}
                        x={xPosition}
                        textAnchor="middle"
                      >
                        {d.post}
                      </Text>
                      <svg
                        viewBox={`0 0 ${ICON_SIZE} ${ICON_SIZE}`}
                        height={ICON_SIZE}
                        width={ICON_SIZE}
                        y={yPosition - ICON_SIZE / HALF_FACTOR}
                        x={xPosition - ICON_SIZE / HALF_FACTOR}
                      >
                        <IconByType type={d.type} size={ICON_SIZE} />
                      </svg>
                    </g>
                  );
                })}
              </g>
            </>
          )}
        </svg>
      </div>
    );
  }
}
export default SchematicMap;
