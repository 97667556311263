"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var gqlType = __importStar(require("./graphql"));
exports.gqlType = gqlType;
var utils_1 = require("../utils");
var EventMirrorComments;
(function (EventMirrorComments) {
  EventMirrorComments["Skip"] = "0000";
  EventMirrorComments["Convert"] = "1111";
})(EventMirrorComments = exports.EventMirrorComments || (exports.EventMirrorComments = {}));
var EventMirrorEventTypes;
(function (EventMirrorEventTypes) {
  EventMirrorEventTypes["Source"] = "test";
  EventMirrorEventTypes["AutomationSource"] = "testaction";
  EventMirrorEventTypes["Target"] = "intrusion";
})(EventMirrorEventTypes = exports.EventMirrorEventTypes || (exports.EventMirrorEventTypes = {}));
var PigStatusValues;
(function (PigStatusValues) {
  PigStatusValues["Moving"] = "moving";
  PigStatusValues["NotDetected"] = "not-detected";
  PigStatusValues["Stopped"] = "stopped";
  PigStatusValues["Stuck"] = "stuck";
  PigStatusValues["Completed"] = "completed";
  PigStatusValues["ApproachingPoi"] = "approaching-poi";
  PigStatusValues["Detected"] = "detected";
})(PigStatusValues = exports.PigStatusValues || (exports.PigStatusValues = {}));
exports.EdgeAgentPigStatusOptions = utils_1.tuple(PigStatusValues.Moving, PigStatusValues.NotDetected, PigStatusValues.Stopped, PigStatusValues.Stuck, PigStatusValues.Completed);
var TrainStatusValues;
(function (TrainStatusValues) {
  TrainStatusValues["Moving"] = "moving";
  TrainStatusValues["NotDetected"] = "not-detected";
  TrainStatusValues["Stopped"] = "stopped";
  TrainStatusValues["Stuck"] = "stuck";
  TrainStatusValues["Completed"] = "completed";
  TrainStatusValues["ApproachingPoi"] = "approaching-poi";
  TrainStatusValues["ApproachingStation"] = "approaching-station";
  TrainStatusValues["Detected"] = "detected";
})(TrainStatusValues = exports.TrainStatusValues || (exports.TrainStatusValues = {}));
exports.EdgeAgentTrainStatusOptions = utils_1.tuple(TrainStatusValues.Moving, TrainStatusValues.NotDetected, TrainStatusValues.Stopped, TrainStatusValues.Stuck, TrainStatusValues.Completed);
var SubscriptionType;
(function (SubscriptionType) {
  SubscriptionType["NEW_SUMMARY"] = "NEW_SUMMARY";
  SubscriptionType["PIG_UPDATE"] = "PIG_UPDATE";
  SubscriptionType["TRAIN_UPDATE"] = "TRAIN_UPDATE";
})(SubscriptionType = exports.SubscriptionType || (exports.SubscriptionType = {}));